'use client';

import React, { ReactElement, useEffect } from 'react';

import { useTracker, UseTrackerProps } from './useTracker';

interface EventComponentProps extends React.PropsWithChildren<{ children: ReactElement }> {
	event: UseTrackerProps;
	onClick?: () => void;
}

export function ComponentTracker({ children, event, onClick }: EventComponentProps) {
	const { trackEvent } = useTracker();

	useEffect(() => {
		if (event.event_type === 'PAGE_LAUNCH') {
			trackEvent(event);
		}
	}, []);

	const handleClick = () => {
		trackEvent(event);
		if (onClick) {
			onClick();
		}
	};

	return React.cloneElement(children as ReactElement, {
		onClick: event.event_type === 'CLICK' ? handleClick : onClick,
	});
}
