'use client';

import Autoplay from 'embla-carousel-autoplay';
import { useEffect, useRef, useState } from 'react';
import { CloudRainWindIcon, ThermometerIcon, ThermometerSunIcon, WindIcon } from 'lucide-react';

import { Carousel, CarouselContent, CarouselItem } from '@/ui/carousel';
import { useWeatherFactsData } from '@/api/weather';
import { cn, messageParser } from '@/lib/utils';
import { Skeleton } from '@/ui/skeleton';
import { Separator } from '@/ui/separator';
import { WeatherFactTypes } from '@/types/constants';

import { METRIC_CONSTANTS } from '../WeatherMap/utils';

interface WeatherFactsProps {
	variant?: 'light' | 'dark';
	className?: string;
}

const factsData = {
	[WeatherFactTypes.HIGHEST_RAIN_ACCUMULATION]: {
		message: `%s${METRIC_CONSTANTS.rain_accumulation.unitPrefix}${METRIC_CONSTANTS.rain_accumulation.unit} rainfall`,
		title: '%s is the wettest locality in %s',
		Icon: CloudRainWindIcon,
		parser: METRIC_CONSTANTS.rain_accumulation.parser,
	},
	[WeatherFactTypes.HIGHEST_TEMPERATURE]: {
		message: `%s${METRIC_CONSTANTS.temperature.unitPrefix}${METRIC_CONSTANTS.temperature.unit} current temperature`,
		title: '%s is the warmest locality in %s',
		Icon: ThermometerSunIcon,
		parser: METRIC_CONSTANTS.temperature.parser,
	},
	[WeatherFactTypes.LOWEST_TEMPERATURE]: {
		message: `%s${METRIC_CONSTANTS.temperature.unitPrefix}${METRIC_CONSTANTS.temperature.unit} current temperature`,
		title: '%s is the coolest locality in %s',
		Icon: ThermometerIcon,
		parser: METRIC_CONSTANTS.temperature.parser,
	},
	[WeatherFactTypes.MAX_WIND_SPEED]: {
		message: `%s${METRIC_CONSTANTS.wind_speed.unitPrefix}${METRIC_CONSTANTS.wind_speed.unit}`,
		title: `%s is the windiest locality in %s`,
		Icon: WindIcon,
		parser: METRIC_CONSTANTS.wind_speed.parser,
	},
};

export function WeatherFacts({ variant, className }: WeatherFactsProps) {
	const plugin = useRef(Autoplay({ delay: 3000 }));
	const isLight = variant === 'light';
	const { data, isLoading, isError } = useWeatherFactsData();
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	/**
	 * DEV: We have intentionally returned
	 * a <br /> tag so that grid system can
	 * auto align the elements, dont't
	 * return null
	 */
	if (isError)
		return (
			<>
				<br className="md:hidden" />
			</>
		);

	if (!data || isLoading || !isClient) {
		return (
			<div className={cn('mt-4 flex flex-row items-center gap-3 py-1 md:mt-0 md:py-0', className)}>
				<Skeleton className="size-7" />
				<div className="flex cursor-default flex-col gap-2">
					<Skeleton className="h-2 w-32" />
					<Skeleton className="h-2 w-40" />
				</div>
			</div>
		);
	}

	if (data.current_city_weather_facts.length === 0) {
		return null;
	}

	return (
		<div
			className={cn(
				'mt-4 flex flex-row items-center duration-500 animate-in fade-in-0 md:mt-0 md:gap-4',
				className,
			)}
		>
			<Separator
				orientation="vertical"
				className={`hidden h-8 w-0.5 rounded-full opacity-40 xl:block ${isLight ? 'bg-white' : 'bg-black'}`}
			/>
			<Carousel
				opts={{
					loop: true,
					align: 'center',
				}}
				plugins={[plugin.current]}
				onMouseEnter={() => plugin.current.stop()}
				onMouseLeave={() => plugin.current.play()}
				className="w-full animate-in fade-in-0 slide-in-from-top-60 md:hidden md:max-w-md xl:block xl:max-w-md"
			>
				<CarouselContent>
					{data.current_city_weather_facts.map((weatherFact, index) => {
						const factTemplate = factsData[weatherFact.label_key];

						if (
							(weatherFact.label_key === WeatherFactTypes.HIGHEST_RAIN_ACCUMULATION &&
								weatherFact.value === '0') ||
							weatherFact.label_key === WeatherFactTypes.MAX_WIND_SPEED ||
							(weatherFact.label_key === WeatherFactTypes.HIGHEST_RAIN_ACCUMULATION &&
								// DEV: for now do a loose check for 0 and fix types
								weatherFact.value == '0') ||
							!factTemplate
						) {
							return null;
						}

						const titleMessage = messageParser(factTemplate.message, [
							factTemplate.parser(Number(weatherFact.value)) ?? 'NA',
						]);

						const subtitleMessage = messageParser(factTemplate.title, [
							weatherFact.locality_name,
							weatherFact.city_name,
						]);

						return (
							<CarouselItem
								key={`${weatherFact.label_key}_${weatherFact.city_id}_${index}`}
								className="-mt-1 flex cursor-grab items-center gap-2 md:px-4"
							>
								<factTemplate.Icon
									className={`size-6 ${isLight ? 'stroke-white' : 'stroke-[#293142]'}`}
								/>
								<div key={index} className={`${isLight ? 'text-white' : 'text-primary'}`}>
									<span className="text-xs font-medium">{titleMessage}</span>
									<p className="max-w-md overflow-hidden text-ellipsis text-nowrap text-xs font-normal">
										{subtitleMessage}
									</p>
								</div>
							</CarouselItem>
						);
					})}
				</CarouselContent>
			</Carousel>
		</div>
	);
}
