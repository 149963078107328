import React, { forwardRef } from 'react';

import { cn } from '@/lib/utils';

// eslint-disable-next-line react/display-name
const Skeleton = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
	return <div ref={ref} className={cn('animate-pulse rounded-md bg-muted', className)} {...props} />;
});

export { Skeleton };
