'use client';

import React from 'react';
import { animated, useScroll } from '@react-spring/web';

import { cn } from '@/lib/utils';

interface DynamicHeaderProps {
	children: React.ReactNode;
	isLight?: boolean;
}

const scrollWhiteBg = ['rgba(255, 255, 255, 0.0)', 'rgba(255, 255, 255, 1)'];
const scrollBlackBg = ['rgba(0, 0, 0, 0.0)', 'rgba(0, 0, 0, 1)'];

export function DynamicHeader({ children, isLight }: DynamicHeaderProps) {
	const containerRef = React.useRef<HTMLDivElement>(null!);

	const { scrollYProgress } = useScroll({
		delay: 500,
	});

	return (
		<animated.div
			className={cn('transition-background overflow-hidden', {
				// 'bg-background/60': !isLight,
				// 'bg-white': isLight,
				// 'sm:bg-transparent sm:backdrop-blur-md': scrollYProgress,
				'border-b border-gray-200': !isLight,
			})}
			style={{
				background: scrollYProgress.to({
					range: [0, 0.01],
					output: !isLight ? scrollWhiteBg : scrollBlackBg,
				}),
			}}
			ref={containerRef}
		>
			<animated.div className={cn('relative mx-auto my-4 hidden flex-row justify-between md:container md:flex')}>
				{children}
			</animated.div>
		</animated.div>
	);
}
