'use client';

import { useEffect, useRef } from 'react';

import { useTracker, UseTrackerProps } from '@/lib/tracking/useTracker';

interface ComponentViewTrackerProps {
	rootMargin?: number;
	threshold?: number;
	elementId: string;
	trackerProps: UseTrackerProps;
}

export function ComponentViewTracker({
	rootMargin = 0,
	elementId,
	threshold = 0.5,
	trackerProps,
}: ComponentViewTrackerProps) {
	const { trackEvent } = useTracker();
	const isObserving = useRef(true);

	useEffect(() => {
		const current = document.getElementById(elementId);

		const removeObserver = () => {
			if (current && isObserving.current) {
				observer.unobserve(current);
			}
		};

		const observer = new IntersectionObserver(
			([entry]) => {
				window.requestAnimationFrame(() => {
					if (entry.isIntersecting) {
						removeObserver();
						isObserving.current = false;
						trackEvent({
							ui_source: 'ELEMENT_IN_VIEW',
							...trackerProps,
						});
					}
				});
			},
			{ rootMargin: `${rootMargin}px`, threshold },
		);

		current && observer?.observe(current);

		return removeObserver;
	}, [elementId, rootMargin, threshold]);

	return null;
}
