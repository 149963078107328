import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_PATH, API_HOST, TOP_WEATHER_CITIES } from '@/lib/constants';
import { getEnvironment } from '@/lib/tracking/utils/environment';
import { useAppStore } from '@/lib/store';

function parseWeatherLocalities(weatherData: WeatherLocationsResponse) {
	const parsedCities = weatherData.city_details
		.map(city => {
			return {
				...city,
				is_top: TOP_WEATHER_CITIES.includes(city.city_id),
			};
		})
		// TODO: Remove once the API is fixed
		.filter(city => city.city_id !== '')
		.sort((a, b) => a.city_name.localeCompare(b.city_name));

	return {
		...weatherData,
		city_details: parsedCities,
	};
}

function convertCityAndLocalityArraysToNestedObjects(data: WeatherLocationsResponse) {
	const citiesObject: ParsedWeatherData = {
		data: {},
		root: {
			default_city_detail: {
				city_id: data.default_city_detail.city_id,
				locality_id: data.default_city_detail.locality_id,
			},
		},
	};

	for (const city of data.city_details) {
		const { localities_details, ...otherData } = city;

		citiesObject.data[city.city_id] = {
			...otherData,
			is_top: TOP_WEATHER_CITIES.includes(city.city_id),
			localities: {},
		};

		for (const locality of localities_details) {
			citiesObject.data[city.city_id].localities[locality.locality_id] = locality;
		}
	}

	return citiesObject;
}

async function getZwsWeatherData(): Promise<WeatherLocationsResponse> {
	try {
		const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';
		const { build, environment } = getEnvironment();
		const response = await fetch(`${API_HOST}${API_BASE_PATH}/service/zws_city_locality_detail`, {
			headers: {
				// Cookie: `${!isProd && 'forceserver=aa*'}`,
				'X-Requested-With': 'Next.js (Build)',
				'X-Build-Version': build,
				'X-App-Environment': environment,
			},
			next: {
				revalidate: 60,
			},
		});

		const data: WeatherLocationsResponse = await response.json();

		if (!data.status) {
			throw new Error(data.message);
		}

		return data;
	} catch (error: any) {
		console.error('getZwsWeatherData ERROR :: ', error.response ?? error);
		throw new Error('Failed to fetch weather data');
	}
}

export async function getParsedWeatherData(): Promise<ParsedWeatherData> {
	const data = await getZwsWeatherData();
	return convertCityAndLocalityArraysToNestedObjects(data);
}

export async function getWeatherLocalities(): Promise<WeatherLocationsResponse> {
	const data = await getZwsWeatherData();
	return parseWeatherLocalities(data);
}

// ====================================

function convertCityWeatherDataToNestedObject(data: WeatherCityDataResponse): ParseCityWeatherData {
	const citiesObject: ParseCityWeatherData = {
		data: {},
		root: null,
	};

	for (const localityWeather of data.localities_details) {
		citiesObject.data[localityWeather.locality_detail.locality_id] = {
			weather_metrics: localityWeather.locality_weather_detail,
			locality_detail: localityWeather.locality_detail,
		};
	}

	return citiesObject;
}

async function getCityWeatherData(city_id: string): Promise<ParseCityWeatherData> {
	const response = await axios.get(`${API_HOST}${API_BASE_PATH}/service/get_city_weather_data?city_id=${city_id}`);

	if (!response.data.status) {
		throw new Error(response.data.message);
	}

	return convertCityWeatherDataToNestedObject(response.data);
}

export function useCityWeatherData(city_id?: string) {
	const developerOptions = useAppStore(state => state.developerOptions);

	return useQuery({
		queryKey: ['city_weather_data', city_id],
		queryFn: () => getCityWeatherData(city_id!),
		enabled: !!city_id,
		refetchInterval: 1000 * developerOptions.liveWeatherupdateInterval,
	});
}

// ====================================

interface DownloadWeatherDataParams {
	locality_id?: string;
	duration_day?: string;
	user_api_key?: string;
	city_id?: string;
}

export async function downloadWeatherData({
	duration_day,
	locality_id,
	city_id,
	user_api_key,
}: DownloadWeatherDataParams): Promise<WeatherDownloadPastDataApiResponse> {
	const response = await axios.get(
		`${API_HOST}${API_BASE_PATH}/external/v0/download_previous_data?city_id=${city_id}&locality_id=${locality_id}&duration_day=${duration_day}`,
		{
			headers: {
				'api-key': user_api_key,
			},
		},
	);

	return response.data;
}

// ====================================

interface WeatherTrendsDataProps {
	city_id: string;
	locality_id: string;
}

async function getWeatherTrendsData({
	city_id,
	locality_id,
}: WeatherTrendsDataProps): Promise<WeatherTrendsDataApiResponse> {
	const response = await axios.get(
		`${API_HOST}${API_BASE_PATH}/service/get_weather_trends_data?city_id=${city_id}&locality_id=${locality_id}`,
	);
	return response.data;
}

export function useWeatherTrendsData({ city_id, locality_id }: Partial<WeatherTrendsDataProps>) {
	return useQuery({
		queryKey: ['trends', city_id, locality_id],
		queryFn: () =>
			getWeatherTrendsData({
				city_id: city_id!,
				locality_id: locality_id!,
			}),
		enabled: !!city_id && !!locality_id,
		staleTime: 1000 * 60,
	});
}

// ==================================== WEATHER FACTS BASED ON CITY AND LOCALITY ID USED IN TOP SECTION

interface WeatherFactsDataProps {
	city_id: string;
	locality_id: string;
}

async function getWeatherFactsData(): Promise<WeatherFactsDataApiResponse> {
	const response = await axios.get(`${API_HOST}${API_BASE_PATH}/service/get_weather_facts`);
	return response.data;
}

export function useWeatherFactsData() {
	return useQuery({
		queryKey: ['weather_facts'],
		queryFn: getWeatherFactsData,
	});
}

// ==================================== WEATHER FACTS BASED ON CITY AND LOCALITY ID USED IN BOTTOM SECTION

interface SmallWeatherFactsDataProps {
	city_id: string;
	locality_id: string;
}

async function getSmallWeatherFactsData({
	city_id,
	locality_id,
}: WeatherFactsDataProps): Promise<WeatherSmallFactsApiResponse> {
	const response = await axios.get(
		`${API_HOST}${API_BASE_PATH}/service/get_locality_weather_facts?city_id=${city_id}&locality_id=${locality_id}`,
	);

	if (response.data.status === false) {
		throw new Error(response.data.message);
	}

	return response.data;
}

export function useSmallWeatherFactsData({ city_id, locality_id }: Partial<SmallWeatherFactsDataProps>) {
	return useQuery({
		queryKey: ['small_facts', city_id, locality_id],
		queryFn: () =>
			getSmallWeatherFactsData({
				city_id: city_id!,
				locality_id: locality_id!,
			}),
		enabled: !!city_id && !!locality_id,
	});
}

// ====================================

interface WeatherCityDataProps {
	longitude: number;
	latitude: number;
}

export async function getLocationFromCoordinates({
	latitude,
	longitude,
}: WeatherCityDataProps): Promise<UserGeoLocationApiResponse> {
	const response = await axios.get(
		`${API_HOST}${API_BASE_PATH}/service/get_location_data_from_geo_point?latitude=${latitude}&longitude=${longitude}`,
	);
	return response.data;
}
