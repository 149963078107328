'use client';

import Link from 'next/link';
import { MenuIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';

import { Avatar, AvatarFallback, AvatarImage } from '@/ui/avatar';
import { Button } from '@/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/ui/sheet';
import { useZomatoUser } from '@/hooks/useZomatoUser';
import { zomatoLogOut } from '@/lib/authentication';
import { Skeleton } from '@/ui/skeleton';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/ui/dropdown-menu';
import { useAppStore } from '@/lib/store';
import { ComponentTracker } from '@/lib/tracking/ComponentTracker';
import { useTracker } from '@/lib/tracking/useTracker';

const DeveloperTools = dynamic(() => import('@/components/DeveloperTools'));

export function UserProfileDesktop({ isLight }: { isLight: boolean }) {
	const [isClient, setIsClient] = useState(false);
	const { isLoggedIn, isLoading, isError, data, isUserZoman } = useZomatoUser();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const setAuthSdkState = useAppStore(state => state.setAuthSdkState);
	const searchParams = useSearchParams();
	const { trackEvent } = useTracker();

	useEffect(() => {
		setIsClient(true);
	}, []);

	if (!isClient || isLoading) {
		return <Skeleton className="size-9 rounded-full border border-black/20" />;
	}

	if (!isLoggedIn || isError || !data || data.status.status === 'failed') {
		return (
			<Button
				size="sm"
				className={`rounded-full ${isLight ? 'text-white hover:text-slate-200' : 'text-primary hover:text-secondary'} bg-transparent px-3 font-normal `}
				asChild
			>
				<Link
					href="/login"
					onClick={() => {
						trackEvent({
							event_type: 'CLICK',
							component: 'SIGN_UP',
							ui_source: 'HEADER',
						});
					}}
				>
					Sign up
				</Link>
			</Button>
		);
	}

	const signOutHandler = () => {
		zomatoLogOut()
			.then(() => {
				setAuthSdkState('logout-success');
			})
			.catch(e => {
				setAuthSdkState('logout-failure');
				console.error(e);
			});
	};

	return (
		<DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
			<DropdownMenuTrigger className="cursor-pointer select-none focus-visible:outline-none">
				<Avatar className="size-9">
					<AvatarImage src={data.response.profile_pic_uri} alt={data.response.name} />
					<AvatarFallback>{data.response.name?.[0]}</AvatarFallback>
				</Avatar>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="mt-6 w-56" align="end" forceMount>
				<DropdownMenuLabel className="font-normal">
					<div className="flex flex-col space-y-1">
						<p className="text-sm font-medium leading-none">{data.response.name}</p>
						<p className="text-xs leading-none text-muted-foreground">{data.response.email}</p>
					</div>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<ComponentTracker
						event={{
							event_type: 'CLICK',
							component: 'DASHBOARD',
							ui_source: 'HEADER',
						}}
					>
						<DropdownMenuItem asChild className="cursor-pointer">
							<Link href="/dashboard">Profile</Link>
						</DropdownMenuItem>
					</ComponentTracker>
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<ComponentTracker
					event={{
						event_type: 'CLICK',
						component: 'LOGOUT',
						ui_source: 'HEADER',
					}}
					onClick={signOutHandler}
				>
					<DropdownMenuItem className="cursor-pointer text-red-500 hover:bg-red-100 hover:text-red-600">
						Log out
					</DropdownMenuItem>
				</ComponentTracker>
			</DropdownMenuContent>
			{(isUserZoman() || searchParams.get('debug')) && <DeveloperTools />}
		</DropdownMenu>
	);
}
export function UserProfileMobile({ isLight }: { isLight: boolean }) {
	const { data, isLoading, isLoggedIn } = useZomatoUser();
	const setAuthSdkState = useAppStore(state => state.setAuthSdkState);
	const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	if (!isClient) {
		return null;
	}

	const signOutHandler = () => {
		zomatoLogOut()
			.then(() => {
				setAuthSdkState('logout-success');
			})
			.catch(e => {
				setAuthSdkState('logout-failure');
				console.error(e);
			})
			.finally(() => {
				setIsSideSheetOpen(false);
			});
	};

	return (
		<Sheet open={isSideSheetOpen} onOpenChange={setIsSideSheetOpen}>
			<SheetTrigger asChild>
				{isLoading ? (
					<Skeleton className="size-10 rounded-full" />
				) : isLoggedIn && data && data.status.status !== 'failed' ? (
					<Avatar className="size-9">
						<AvatarImage src={data.response.profile_pic_uri} alt={data.response.name} />
						<AvatarFallback>{data.response.name?.[0]}</AvatarFallback>
					</Avatar>
				) : (
					<Button
						size="icon"
						className={`${!isLight ? 'text-primary' : 'text-white'} hover:bg-transparent`}
						variant="ghost"
					>
						<MenuIcon size={28} />
					</Button>
				)}
			</SheetTrigger>
			<SheetContent className="px-2 py-4" onOpenAutoFocus={e => e.preventDefault()}>
				<div className="flex w-fit flex-col items-start gap-4">
					<ComponentTracker
						event={{
							event_type: 'CLICK',
							component: 'HOME',
							ui_source: 'HEADER',
						}}
					>
						<Button className="rounded-full" variant="ghost" asChild>
							<Link href="/">Home</Link>
						</Button>
					</ComponentTracker>
					<ComponentTracker
						event={{
							event_type: 'CLICK',
							component: 'DASHBOARD',
							ui_source: 'HEADER',
						}}
					>
						<Button className="rounded-full" variant="ghost" asChild>
							<Link href="/dashboard/">Profile</Link>
						</Button>
					</ComponentTracker>
					<ComponentTracker
						event={{
							event_type: 'CLICK',
							component: 'CONTACT_US',
							ui_source: 'HEADER',
						}}
					>
						<Button className="rounded-full" variant="ghost" asChild>
							<Link href="/contact/">Contact us</Link>
						</Button>
					</ComponentTracker>
					<ComponentTracker
						event={{
							event_type: 'CLICK',
							component: 'VOLUNTEER_WITH_US',
							ui_source: 'HEADER',
						}}
					>
						<Button className="rounded-full" variant="ghost" asChild>
							<Link href="/volunteer/">Volunteer</Link>
						</Button>
					</ComponentTracker>
					{!isLoggedIn ? (
						<Button
							variant="ghost"
							className="rounded-full text-red-500 hover:bg-red-100 hover:text-red-600"
							asChild
						>
							<Link href="/login">Log in</Link>
						</Button>
					) : (
						<Button
							variant="ghost"
							onClick={signOutHandler}
							className="rounded-full text-red-500 hover:bg-red-100 hover:text-red-600"
						>
							Log out
						</Button>
					)}
				</div>
			</SheetContent>
		</Sheet>
	);
}
