'use client';

import Link from 'next/link';
import { Suspense } from 'react';

import { BASE_PATH } from '@/lib/constants';
import { Button } from '@/ui/button';
import { ComponentTracker } from '@/lib/tracking/ComponentTracker';
import { cn } from '@/lib/utils';

import { UserProfileDesktop, UserProfileMobile } from './UserProfile';
import { WeatherFacts } from './WeatherFacts';
import { DynamicHeader } from './DynamicHeader';
import { ClientErrorBoundary } from '../ClientErrorBoundary';
import { ProductLogo } from '../ProductLogo';

interface HeaderProps {
	variant?: 'default' | 'transparent';
	className?: string;
}

export function HeaderDesktop({ variant }: HeaderProps = { variant: 'default' }) {
	const isLight = variant === 'transparent';

	return (
		<DynamicHeader isLight={isLight}>
			<div className="flex items-center gap-4 overflow-hidden">
				<Link
					href={BASE_PATH}
					className="h-fit rounded-sm ring-offset-background transition-colors animate-in focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring"
				>
					<ProductLogo className="w-[10.5rem]" variant={isLight ? 'light' : 'dark'} priority={true} />
				</Link>
				<ClientErrorBoundary>
					<WeatherFacts variant={isLight ? 'light' : 'dark'} />
				</ClientErrorBoundary>
			</div>
			<div className="flex items-center gap-0">
				<ComponentTracker
					event={{
						event_type: 'CLICK',
						component: 'VOLUNTEER_WITH_US',
						ui_source: 'HEADER',
					}}
				>
					<Button
						asChild
						size="sm"
						className={`rounded-full font-normal lg:px-3 ${isLight ? 'text-white hover:text-slate-200' : 'text-primary hover:text-gray-700'} hover:bg-transparent`}
						variant="ghost"
					>
						<Link href="/volunteer/">Volunteer</Link>
					</Button>
				</ComponentTracker>
				<ComponentTracker
					event={{
						event_type: 'CLICK',
						component: 'CONTACT_US',
						ui_source: 'HEADER',
					}}
				>
					<Button
						asChild
						size="sm"
						className={`rounded-full font-normal lg:px-3 ${isLight ? 'text-white hover:text-slate-200' : 'text-primary hover:text-gray-700'} hover:bg-transparent`}
						variant="ghost"
					>
						<Link href="/contact/">Contact us</Link>
					</Button>
				</ComponentTracker>
				<div className="flex items-center gap-4">
					<ClientErrorBoundary componentName="HEADER_DESKTOP">
						<Suspense>
							<UserProfileDesktop isLight={isLight} />
						</Suspense>
					</ClientErrorBoundary>
					<ComponentTracker
						event={{
							event_type: 'CLICK',
							component: 'FREE_API',
							ui_source: 'HEADER',
						}}
					>
						<Button
							size="sm"
							className={`rounded-full bg-transparent px-4 transition-colors ${isLight ? 'border-white text-white hover:bg-white hover:text-primary' : 'border-[#293142] text-primary hover:bg-black hover:text-white'}`}
							variant="outline"
							asChild
						>
							<Link href="/dashboard/">Free API</Link>
						</Button>
					</ComponentTracker>
				</div>
			</div>
		</DynamicHeader>
	);
}

export function HeaderMobile({ variant }: HeaderProps = { variant: 'default' }) {
	const isLight = variant === 'transparent';

	return (
		<div className={`md:hidden ${isLight ? 'bg-black/50 backdrop-blur-xl' : 'border-b border-gray-200'} py-3`}>
			<div className="container flex items-center justify-between gap-4 sm:container">
				<Link
					href={BASE_PATH}
					className="h-fit w-40 rounded-sm p-1 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 lg:w-auto"
				>
					<ProductLogo className="w-[10.5rem]" variant={isLight ? 'light' : 'dark'} priority={true} />
				</Link>
				<div className="flex items-center gap-4">
					<ComponentTracker
						event={{
							event_type: 'CLICK',
							component: 'FREE_API',
							ui_source: 'HEADER',
						}}
					>
						<Button
							size="sm"
							className={`rounded-full bg-transparent transition-colors ${isLight ? 'border-white text-white hover:bg-white hover:text-primary' : 'border-[#293142] text-primary hover:bg-black hover:text-white'}`}
							variant="outline"
							asChild
						>
							<Link href="/dashboard/">Free API</Link>
						</Button>
					</ComponentTracker>
					<ClientErrorBoundary componentName="HEADER_MOBILE">
						<Suspense>
							<UserProfileMobile isLight={isLight} />
						</Suspense>
					</ClientErrorBoundary>
				</div>
			</div>
		</div>
	);
}

export function Header({ variant, className }: HeaderProps = { variant: 'default' }) {
	return (
		<header className={cn('z-50 md:sticky md:top-0', className)}>
			<HeaderDesktop variant={variant} />
			<HeaderMobile variant={variant} />
		</header>
	);
}
