'use client';

import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

import { useTracker } from '@/lib/tracking/useTracker';

import { reportErrorToDD } from './WeatherMap/utils';

type ClientErrorBoundaryProps = {
	children: React.ReactNode;
	fallback?: React.ReactNode;
	componentName?: string;
} & Omit<ErrorBoundaryProps, ''>;

export function ClientErrorBoundary({
	children,
	fallback = null,
	componentName = 'NA',
	...props
}: ClientErrorBoundaryProps) {
	const { trackEvent } = useTracker();

	return (
		// @ts-ignore
		<ErrorBoundary
			fallback={fallback}
			onError={(error, componentStack) => {
				reportErrorToDD(error, {
					componentName: 'ClientErrorBoundary',
					componentStack,
				});

				if (process.env.NEXT_PUBLIC_APP_ENV !== 'prod') {
					console.debug('[DEBUG] :: ERROR BOUNDARY : ', { error, componentStack });
				}

				try {
					trackEvent({
						event_type: 'ERROR_BOUNDARY',
						component: componentName,
						meta_data: {
							error: error.toString(),
						},
					});
				} catch (error) {
					console.debug('[DEBUG] :: ERROR BOUNDARY CATCH WRAPPER : ', { error, componentStack });
				}
			}}
			{...props}
		>
			{children}
		</ErrorBoundary>
	);
}
