//@ts-ignore
import Zoauth2 from '@zomato/zoauth';

/**
 * Initialize the OAuth Login SDK.
 */

// Singleton for Zauth init
const Zauth = function () {
	// Private variable
	let instance: any;

	// Private method
	function init() {
		const auth = Zoauth2({
			client_id: 'bb687dd1-e9bc-413a-97c7-50ea6eea6856',
			scope: 'offline',
			enable_one_tap: false,
			login_methods: ['google', 'email'],
			custom: false,
		});
		return { auth };
	}

	// Public method to get the instance
	return {
		getInstance: function () {
			if (!instance) {
				instance = init();
			}
			return instance;
		},
	};
};

export const zomatoSignIn = async () => Zauth().getInstance().auth.login('IFRAME', 'login_ui');
export const zomatoLogOut = async () => Zauth().getInstance().auth.logout();
export const closeAuthIframe = () => {
	const instance = Zauth().getInstance();
	if (instance.auth) {
		instance.auth.closeAuthIframe();
	}
};
