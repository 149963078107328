import Image from 'next/image';

import WeatherUnionLogoDark from '@/public/weatherunion-logo-dark.svg';
import WeatherUnionLogoLight from '@/public/weatherunion-logo-light.svg';
import { PRODUCT_NAME } from '@/lib/constants';

type NextImageProps = Omit<React.ComponentProps<typeof Image>, 'src' | 'alt'>;

interface ProductLogoProps extends NextImageProps {
	className?: string;
	variant?: 'dark' | 'light';
	src?: string;
	alt?: string;
}

const PRODUCT_LOGO_ALT = `${PRODUCT_NAME} - A Zomato Giveback`;

export function ProductLogo({ className, variant, src, alt, ...props }: ProductLogoProps) {
	const ImageUrl = variant === 'dark' ? WeatherUnionLogoDark : WeatherUnionLogoLight;

	return <Image src={src ?? ImageUrl} className={className} alt={alt ?? PRODUCT_LOGO_ALT} {...props} />;
}
